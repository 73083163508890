.website-configurator-wrapper {

  .mobile-d-none {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .desktop-d-none {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .load-all-extensions {
    text-align: center;
  }

  .website-configurator-item {
    background-color: #fff;
    box-shadow: 0 24px 64px 0 rgba(22,28,45,0.1);
    padding: 18px 30px 18px 30px;
    border-radius: 6px;
    margin-bottom: 20px;
    border: 2px solid white;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    @include media-breakpoint-up(lg) {
     display: flex;
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        box-shadow: 0 24px 64px 0 rgba(22,28,45,0.2);
      }
      cursor: pointer;
    }

    &.active {
      background-color: #EAF4FF;
      border-color: #057BFF;
    }

    .form-switch {
      padding-left: 0;
      margin-right: 20px;
      margin-bottom: 10px;

      .website-configurator-switch {
        margin-left: 0;
        position: relative;
        width: 29px;
        height: 18px;
        margin-top: 4px;
        background-size: 16px;
        background-color: #fff;
        border: 1px solid #D8D8D8;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23D8D8D8'/%3E%3C/svg%3E");
        transition: background-color .15s ease-in-out, border-color .15s ease-in-out, background-position .15s ease-in-out;

        &:checked {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E");
          background-color: #057BFF;
          border-color: #057BFF;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .title {
      max-width: 384px;
    }

    .website-configurator-item-content {
      position: relative;
      width: 100%;

      .website-configurator-item-price {
        font-size: 19px;
        line-height: 26px;
        font-style: italic;
        margin-bottom: 15px;
        font-family: Montserrat,serif;

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      p {
        font-size: 15px;
        color: #072D38;
      }

      ul:not(.pagination):not(.list-social) {
        padding-left: 33px;

        li {
          font-size: 15px;
          color: #072D38;

          &:before {
            position: absolute;
            content: "";
            width: 3px;
            height: 3px;
            left: -22px;
            top: 10px;
            text-align: center;
            border-radius: 100%;
            background-color: #072D38;
            padding: 0;
          }
        }
      }
    }
  }

  .website-configurator-request {
    position: sticky;
    top: 48px;
    margin-bottom: 20px;
    margin-top: 48px;
    background-color: #F1F4F8;
    padding: 22px 33px 28px;
    border-radius: 6px;

    .website-configurator-request-title {
      font-size: 14px;
      line-height: 28px;
      text-transform: uppercase;
    }
    .website-configurator-final-price {
      font-size: 27px;
      line-height: 40px;
      font-style: italic;
      font-family: Montserrat,serif;
    }
    .website-configurator-price-notice {
      font-size: 10px;
      line-height: 21px;
      position: relative;
      top: -6px;
      margin-bottom: 13px;
    }
    p {
      font-size: 15px;
      color: #072D38;
      margin-bottom: 22px;
    }
  }
}

#website-configurator-request {
  .modal-dialog {
    max-width: 670px;
  }

  .modal-content {
    .modal-header {
      border: none;
    }

    .modal-body {
      padding-top: 0;

      .notice {
        margin-bottom: 28px;

        img {
          width: 31px;
          margin-right: 4px;
        }
      }

      form {
        .control-label {
          display: none;
        }

        .form-control {
          border-color: #d0dae7;
        }

        span.error {
          color: red;
        }

        .form-navigation {
          display: flex;
          justify-content: center;
          margin-top: 24px;
        }

        .form-check {
          padding-left: 0;
        }

        .form-check .form-check-label {
          position: relative;
          padding-left: 44px;

          input {
            opacity: 0;
            position: absolute;
          }

          &:before {
            content: "";
            margin-left: 0;
            position: absolute;
            left: 0;
            width: 29px;
            height: 18px;
            margin-top: 4px;
            background-size: 16px;
            background-color: #fff;
            border: 1px solid #D8D8D8;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23D8D8D8'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, background-position .15s ease-in-out;
            background-position: 0;
            border-radius: 2.5rem;
            vertical-align: top;
            display: inline-block;
          }

          &.checked {
            &:before {
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E");
              background-color: #057BFF;
              border-color: #057BFF;
              background-position: 100%;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        .website-configuration-field {
          position: absolute;
          opacity: 0;
          pointer-events: none;
        }

        .btn-group>.btn-group:not(:last-child)>.btn {
          border-top-right-radius: .375rem;
          border-bottom-right-radius: .375rem;
        }

        .form-btn-loader {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          text-align: center;
          top: 0;
          transform: translateY(100px);
          width: 100%;
          background-color: #ff5400;
        }

        .btn-group.submit {
          overflow: hidden;
        }

        .btn[type=submit] {

          &.loading {
            pointer-events: none;

            .form-btn-loader {
              animation: slideRightIn 0.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
              text-align: center;

              > span {
                animation: loaderProgression 1.5s cubic-bezier(0.86, 0, 0.07, 1) infinite;
                background-color: #fff;
                border-radius: 100px;
                display: inline-block;
                height: 10px;
                margin: 0 2.5px;
                opacity: 0;
                width: 10px;

                &:nth-child(1) {
                  animation-delay: 0;
                }

                &:nth-child(2) {
                  animation-delay: 0.125s;
                }

                &:nth-child(3) {
                  animation-delay: 0.25s;
                }

                &:nth-child(4) {
                  animation-delay: 0.375s;
                }

                &:nth-child(5) {
                  animation-delay: 0.625s;
                }

              }
            }
          }

          &.complete {
            .form-btn-loader {
              animation: slideRightOut 0.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
            }
          }
        }

        @keyframes slideRightOut {
          0% {
            transform: translateX(0%);
            opacity: 1;
          }

          100% {
            transform: translateX(120%);
            opacity: 0;
          }
        }

        @keyframes slideRightIn {
          0% {
            transform: translateX(-120%);
            opacity: 0;
          }

          100% {
            transform: translateY(0%);
            opacity: 1;
          }
        }

        @keyframes loaderProgression {
          0%,
          100% {
            opacity: 0.5;
            transform: scale(0.8);
          }

          30%,
          60% {
            opacity: 1;
            transform: scale(1);
          }
        }

      }
    }
  }
}