.device.device-macbook {
  margin-top: -1.5rem;
}

.device.device-iphonex {
  margin-top: -1rem;
}

.text-trenner {
  height: 1px;
  background-color: #CED9E8;
}