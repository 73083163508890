li.page-item.current {

  a{
    background-color: #ff5400;
    color: #fff;
  }
}

body.startpage {
  .section-end-shape.blog-in-footer {
	  padding-top: 17rem !important;
  }

  footer {
	.tx-blog-post-list {
	  margin-top: -275px;

	  > .container > .row {
		padding-bottom: 0 !important;
		border-bottom: none !important;
	  }
	}
  }
}

.tx-blog-post-list .flickity-enabled .blog-post-start {
  height: 100%;

  > .card {
	height: 100%;
  }
}

.blog-comment-form {

  .alert {
    background-color: #f1f4f8;
    border-color: #f1f4f8;

    .alert__list li {
      color: #ff5400;
    }
  }
}

.blog-comment {
  margin-bottom: 25px;

  &:first-of-type {
    margin-top: 25px;
  }

  .comment-avatar img {
    border-radius: 100%;
  }

  .media-body {
    padding-left: 30px;
    .author {
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
}