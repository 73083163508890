nav{
  font-family: Montserrat,serif;
}

.nav-link{
  font-size: 1.125rem;
  line-height: 1.2222;

  > .badge {
    color: #fff;
    background-color: rgba(7,45,56,.314);
  }

  &:hover > .badge, &:focus > .badge, &.active > .badge {
    background-color: #ff5400;
  }
}

.sub-link {
  color: #072D3850;
  transition: all .2s ease;

  &:hover {
	color: #ff5400;
  }
}

.navbar-brand-img{
  max-width: 90px;

  @include media-breakpoint-up(lg) {
    max-height:2.4rem;
    max-width: none;
  }
}

.navbar {
  padding: 11px 0;

  @include media-breakpoint-up(lg) {
    padding: 2.2rem 20px 1.25rem 20px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(7,45,56,.314);
  transition: all .2s ease;
}

.navbar-expand-lg {
  > .container, > .container-fluid {
    padding: 0 20px;
  }
}

@include media-breakpoint-up(lg) {
  .sidenav{
	position:fixed;
	top:84px;
	height:calc(100vh - 84px);
	overflow:auto
  }

  .navbar-collapse > nav {
	position: relative;
	top: 3px;
  }

  .sidenav-left{
	left:0
  }

  .sidenav-right{
	right:0
  }

  .navbar-light .navbar-nav .nav-link {
	padding-right: .85rem;
	padding-left: .85rem;
  }

  .navbar-expand-lg {
    .navbar-nav {
      margin-right: -10px;
    }

    .navbar-collapse {
      justify-content: flex-end;
    }
  }
}