

section .frame-type-menu_subpages ul:not(.pagination):not(.list-social) li::before, section ul.glossar-sidebar:not(.pagination):not(.list-social) li::before{
  content: none;
}
section .frame-type-menu_subpages ul:not(.pagination):not(.list-social), section  ul.glossar-sidebar:not(.pagination):not(.list-social){
  padding-left: 0;
}

.row-mobile-reversed {
  flex-direction: column-reverse;
  @include media-breakpoint-up(md){
    flex-direction: row;
  }
}

.frame-type-menu_subpages li, .glossar-sidebar li {
  margin-bottom: 11px;
  background-color: #fff;

  a {
    color: #000;
    display: block;
    padding: 15px 20px;
    border-radius: 10px;
    padding-right: 50px;

    &::after {
      background-image: url("/typo3conf/ext/do_customer/Resources/Public/Icons/arrow-right.svg");
      position: absolute;
      right: 23px;
      margin: auto 0;
      content: "";
      width: 22px;
      height: 18px;
      background-size: contain;
      top: 20px;
      opacity: 0;
      transition: opacity .35s ease-in-out;
    }
    &:hover{

      box-shadow: 0 2px 50px 0 rgba(0,0,0,0.06);
      &::after{
        opacity: 1;
      }
    }
  }
  span.active{
    font-weight: 700;
    color: #000;
    display: block;
    padding: 15px 20px;
    border-radius: 10px;
  }
}


