@media (min-width:416px) {
  .frame-type-docustomer_cta {
    .btn:first-of-type{
      margin-right: .75rem;
    }
    .btn + .btn {
      margin-left: 0.75rem;
    }
  }
}

button.btn {
  display: flex;
  align-items: center;
}

.frame-type-docustomer_container .frame-type-docustomer_cta {
  padding: 0;

  > div {
    padding: 50px 0 !important;
  }

  .btn-mail {
    margin-left: 0 !important;
  }

  .btn-phone {
    margin-right: 0;
  }


  @media (min-width:448px) {
    .btn-phone {
      margin-right: .75rem;
    }
  }

  @media (min-width:484px) {
    .btn-mail {
      margin-left: 1.5rem !important;
    }
  }

  @media (min-width:768px) {
    .btn-mail {
      margin-left: 0 !important;
      margin-top: 25px;
    }
  }

  @media (min-width:1200px) {
    .btn-mail {
      margin-left: 1.5rem !important;
      margin-top: 0;
    }
  }

}