body .cc_banner.cc_container {
  font-family: OpenSans,sans-serif;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  z-index: 9999;
  right: 2.2em;
  bottom: 2.2em;
  left: 2.2em;
  max-width: 24em;
  flex-direction: column-reverse;
  background-color: #fff;
  padding: 4.2em 2.2em 2.2em;
  border-radius: 5px;
  border: none;
  animation-name: showing;
  animation-duration: 1s;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  box-shadow: 0 0 50px 0 rgba(157,163,174,.65);

  @media all and (min-width: 500px) {
	left: auto;
  }

  &.cc_container--open {
	opacity: 1;
  }

  &.move {
	transform: translateX(500px);
	transition: transform .5s ease-in-out,opacity 0s ease-in-out 1s;
	opacity: 0;
  }

  &:before {
	position:absolute;
	top: 2.2em;
	left: 0;
	right: 0;
	bottom: auto;
	content: " ";
	background: url("../images/soccer-ball2x.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 25px;
	height: 25px;
	display: block;
  }

  .cc_message {
	font-size: 14px !important;
	line-height: 1.5em !important;
	margin-bottom: 1.2em;
	animation: none;

	a.cc_more_info {
	  display: inline;
	  color: #ff5400;
	}
  }

  .cc_btn {
	font-family: Montserrat,sans-serif;
	font-weight: 600;
	margin: 0 auto;
	font-size: 15px !important;
	border: 2px solid #ff5400;
	color: #fff;
	background-color: #ff5400;
	padding: .8125rem 1.25rem;
	border-radius: .375rem;
	width: 100%;
	max-width: 130px;
	box-shadow: 0 .5rem 1.5rem rgba(7,45,56,.1)!important;
	transition: all .2s ease-in-out;
	animation: none;

	&:hover {
	  border: 2px solid #d94700;
	  background-color: #d94700;
	}
  }
}

@keyframes showing {
  from {opacity: 0;}
  to {opacity: 1;}
}