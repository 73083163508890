
img {max-width: 100%; height: auto}

// Bootstrap functions
@import '../node_modules/bootstrap/scss/functions';

// MMenu scss
@import "../node_modules/mmenu-js/src/mmenu";

// import vendor sass resources
@import "vendor/slick";
@import "vendor/slick-theme";

// Theme functions
@import 'theme/functions';

// Custom variables
@import 'user-variables';

// Theme variables
@import 'theme/variables';

// Bootstrap core
@import '../node_modules/bootstrap/scss/bootstrap';

// Theme core
@import 'theme/theme';


// Custom core
@import 'user';

@import "vendor/aos";

@import "elements/hamburger-icon";
@import "elements/navigation";
@import "elements/mmenu";
@import "elements/texttile";
@import "elements/buttons";
@import "elements/spielerkachel";
@import "elements/slider";
@import "elements/badge";
@import "elements/zitat";
@import "elements/icon-text";
@import "elements/3column-icon-teaser";
@import "elements/karrieremenu";
@import "elements/blog";
@import "elements/mindshape-cookie";
@import "elements/cookie-consent";
@import "elements/newsletter";
@import "elements/glossar";
@import "elements/faq";
@import "elements/media";
@import "elements/form-switch";
@import "elements/do_pricingtables";
@import "elements/do_website-configurator";

@import "layout/footer";
@import "layout/typographie";
@import "layout/fsc";
@import "layout/background";
@import "layout/spacing";

body.safari iframe.device-screen {
  width: 100% !important;
  left: 2px !important;
  top: 14.8% !important;
}

body.safari .jarallax > div > div {
  position: relative !important;
  margin-top: 0 !important;
  transform: none !important;
}

#anfrageform {
  .modal-header {
    height: 0;
    border: none;
  }

  .form-group {
    display: block !important;

    label {
      display: none;
    }
  }
}

html body.mm-wrapper {
  overflow: clip;
}

body, html {
  scroll-behavior: smooth;
}