.section-end-shape {

  background-color: $gray-100;

  .bg-none + & {
    background-color: transparent;
  }

  .bg-light + & {
    background-color: $gray-100;
  }

  .bg-white + & {
    background-color: #fff;
  }

  .bg-gradient-light + & {
    background-color: #fff;
  }

  .bg-gray-200 + & {
    background-color: $gray-200;
  }

  .bg-dark + & {
    background-color: #072d38;
  }
}