body {
  .klaro.we_cookie_consent .cookie-notice {
	padding: 22px 25px;
	font-size: 15px;
	box-shadow: 0 2px 50px 0 rgba(0,0,0,0.13);

	@include media-breakpoint-up(md) {
	  padding: 44px 50px;
	}
  }

  .klaro.we_cookie_consent .cookie-modal .cm-modal {
	bottom: 0;
	max-height: calc(100%);
	position: fixed;
  }

  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer {
	z-index: 10000;
	height: 110px;
  }

  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body {
	overflow: visible;
	padding-bottom: 110px;

	@include media-breakpoint-up(md) {
		padding-bottom: 0;
	}
  }

  .klaro.we_cookie_consent .cookie-notice .cn-body p.cn-ok {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
  }

  .klaro.we_cookie_consent .cookie-modal .cm-app-label .slider::before, .klaro.we_cookie_consent .cookie-notice .cm-app-label .slider::before {
	background-color: #C9C9C9;
  }

  .klaro.we_cookie_consent .cookie-modal .cm-app-input:checked + .cm-app-label .slider::before, .klaro.we_cookie_consent .cookie-notice .cm-app-input:checked + .cm-app-label .slider::before {
	background-color: #ff5400;
  }

  .klaro.we_cookie_consent .cookie-notice .slider, .klaro.we_cookie_consent .cookie-modal .slider, .klaro .cookie-modal .cm-app-input:focus+.cm-app-label .slider, .klaro .cookie-notice .cm-app-input:focus+.cm-app-label .slider {
	box-shadow: none;
  }

  .klaro.we_cookie_consent .cookie-modal h1, .klaro.we_cookie_consent .cookie-modal h2 {
	font-family: Montserrat,sans-serif;
	color: #072d38;
	font-weight: 600;
  }

  .klaro.we_cookie_consent .cookie-notice p {
	color: #6a8188;

	  @include media-breakpoint-down(sm) {
		  &:first-of-type {
			  display: block;
			  height: 100px;
			  overflow: scroll;
		  }
	  }
  }

  .klaro.we_cookie_consent .cookie-notice a {
	color: #ff5400;
  }

  .klaro.we_cookie_consent .cookie-notice .cn-body p.cn-ok .cm-btn {
	color: #ffffff;
	padding: 1em 1.5em;
	position: relative;
	opacity: 1;
	font-size: 15px;
	width: auto;
	  margin: 10px 0;
	order: 3;
	border: 1px solid #ff5400;
	background-color: #ff5400;
	text-align: left;
	display: inline-flex;
	transition: all .25s ease-in-out;

	  @include media-breakpoint-down(sm) {
		display: none;
		  padding: 7px 14px;
		  margin-bottom: 0;
	  }

	@include media-breakpoint-up(md) {
	  order: 2;
	  margin: 0 20px 0 auto;
	  width: auto;
	  display: none;
	}

	&:hover {
	  background-color: #d94700;
	  color: #fff;
	}

	&:after {
	  content: "";
	  position: absolute;
	  left: 0;
	  top: 50%;
	  bottom: 0;
	  width: 16px;
	  height: 16px;
	  transform: translateY(-50%);
	}

	&.cm-btn-info {
	  order: 2;
	  padding-left: 27px;
	  font-size: 15px;
	  display: inline-flex;
	  border: none;
		color: #ff5400;
	  margin: 10px 0;
	  background-color: transparent;

		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}

	  @include media-breakpoint-up(md) {
		margin: 0;
		order: 1;
	  }

	  &:after {
		background-image: url("/typo3conf/ext/do_customer/Resources/Public/images/setup-preferences.svg");
		height: 14px;
	  }

	  &:hover {
		color: #b33b00;
	  }
	}

	&.cm-btn-success {
	  order: 3;
	  font-size: 15px;
	  align-self: center;
	  width: auto;
	  display: inline-flex;

	  @include media-breakpoint-up(md) {
		margin: 0;
		order: 3;
	  }
	}
  }

  @include media-breakpoint-up(md) {
	.klaro.we_cookie_consent .cookie-notice .cn-body p:first-of-type {
	  max-width: 80%;
	}
  }

  .klaro.we_cookie_consent .cookie-notice .cn-body p.cn-ok .cm-btn.cm-btn-success, .klaro.we_cookie_consent .cookie-modal .cm-btn.cm-btn-success, .tx-we-cookie-consent a.btn-primary {
	background-color: #ff5400;
	font-family: Montserrat,sans-serif;
	font-weight: 400;
	color: #fff;
	border-radius: .375rem;
	padding: 14px 23px;
	transition: all .25s ease-in-out;

	  @include media-breakpoint-down(sm) {
		padding: 7px 14px;
		  margin: 0;
	  }

	&:hover {
	  background-color: #d94700;
	}

	&:after {
	  display: none;
	}
  }

	.klaro.we_cookie_consent .cookie-notice .cn-body p.cn-ok .cm-btn.cm-btn-success {
		@include media-breakpoint-down(sm) {
			margin-right: 20px;
			margin-top: 10px;
		}
	}

  .tx-we-cookie-consent .table th, .tx-we-cookie-consent .table td {
	padding: 1.5rem 2rem;
	vertical-align: top;
	border-top: 1px solid #f1f4f8;
  }

  .tx-we-cookie-consent .table thead th {
	padding-bottom: 1.5rem;
	text-transform: unset;
	color: #000;
	font-size: 1.063rem;
	font-weight: 400;
  }

  .tx-we-cookie-consent .table-striped {
	padding: 1.5rem 2rem;
	vertical-align: top;
	border-top: 1px solid #f1f4f8;

	thead th {
	  border-bottom: none;
	}

	tbody tr:nth-of-type(2n+1) {
	  background-color: #f9fbfd;

	  &:hover {
		background-color: #f9fbfd;
	  }
	}

	tbody tr td:first-of-type {
	  color: #000;
	}
  }

  .tx-we-cookie-consent .service__headline {
	display: block;
	line-height: 1.75rem;
	font-size: 1.3125rem;
	font-family: Montserrat,serif;
	margin-bottom: .5rem;
	font-weight: 600;
	letter-spacing: 0;
  }

  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-header, .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body, .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer, .klaro.we_cookie_consent .cookie-modal p {
	color: #6a8188;
  }

  .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app span.cm-app-title, .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app span.cm-opt-out, .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app span.cm-required {
	color: #072D38;
	font-weight: 400;
	font-size: 15px;
  }

  .klaro.we_cookie_consent .cookie-notice a, .klaro.we_cookie_consent .cookie-modal a {
	color: #ff5400;
	text-decoration: none;
	transition: all .25s ease-in-out;

	&:hover {
	  color: #d94700;
	}
  }

  .klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer a.cm-powered-by {
	display: none;
  }

  @include media-breakpoint-up(md) {
	.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-header {
		margin: 44px 50px 22px;
	}

	.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-body {
	  margin: 0 50px 22px;
	}

	.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer {
	  padding: 22px 50px;
	  height: auto;

	  &:after {
		display: block;
		content: " ";
		clear: both;
	  }
	}

	.klaro.we_cookie_consent .cookie-modal .cm-modal .cm-footer button {
	  float: right;
	}
  }
}