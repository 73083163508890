.frame-type-docustomer_texttile {
  background-color: $gray-100;
  padding-top: 6rem;
  padding-bottom: 5rem;

  &:before, &:after{
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    left: -50%;
    background-color: $gray-100;
    top: 0;
  }
  &:after{
    left: auto;
    right: -50%;
  }

  .container{
    margin: 0 auto;
  }
}

.text-tile-header{
  margin-top: 17px;
  margin-bottom: 0;
}