// Thanks to http://callmenick.com/post/animating-css-only-hamburger-menu-icons

.c-hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 40px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  height: 50px;

/*
  @include media-breakpoint-up(md) {
	width: 75px;
  }
  */
}

.c-hamburger:focus {
  outline: none;
}

.c-hamburger span {
  display: block;
  position: absolute;
  top: 26px;
  left: 5px;
  right: 11px;
  height: 3px;
  border-radius: 50px;
  background: #23506690;
}

.c-hamburger span::before,
.c-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: #23506690;
  content: "";

  .mm-opened & {
	background-color: #fff;
  }
}

.c-hamburger span::before {
  top: -9px;
}

.c-hamburger span::after {
  bottom: -9px;
}

.c-hamburger--htx {
  background-color: transparent;
  z-index: 2;
}

.c-hamburger--htx span {
  transition: background 0s 0.3s;
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
  transition-property: top, transform;
}

.c-hamburger--htx span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.c-hamburger--htx.is-active {
  background-color: transparent;
}

.c-hamburger--htx.is-active span {
  background: none;
}

.c-hamburger--htx.is-active span::before {
  top: 0;
  transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.c-hamburger--htx.is-active span::before,
.c-hamburger--htx.is-active span::after {
  transition-delay: 0s, 0.3s;
}

.page-header-navtoggle {
	.c-hamburger span::before,
	.c-hamburger span::after {
	}

	.c-hamburger span {
		background: #23506690;
	}

	.c-hamburger--htx.is-active span {
	  background: none;
	}

}
