.badge {
  font-family: Montserrat;
  letter-spacing: 0.1px;
  font-weight: 400;
}

.badge-light, .badge-light-soft {
  color: #072D38;
}

.badge-pill {
  padding: 0.5rem 1rem;
}

.badge.bg-primary-soft {
  border: 1px solid #F1F4F8;
}