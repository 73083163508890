//
// card.scss
// Extended from Bootstrap
//

.card {
  flex-direction: column;
  width: 100%; // IE fix
}

.card-header,
.card-body,
.card-footer,
.card-meta,
[class*="card-img"] {
  position: relative;
  display: block;
  flex-grow: 0;
  width: inherit;
  min-height: 1px; // IE fix
}

// Card link

a.card,
a.card-header,
a.card-body,
a.card-footer,
a.card-meta {
  color: inherit;

  @include hover {
    text-decoration: none;
  }
}


// Card image

.card-img-right {
  border-radius: $border-radius $border-radius 0 0;

  @include media-breakpoint-up(md) {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.card-img-left {
  border-radius: $border-radius $border-radius 0 0;

  @include media-breakpoint-up(md) {
    border-radius: $border-radius 0 0 $border-radius;
  }
}


// Card image slider

.card-img-slider {
  
  @include media-breakpoint-up(md) {
    height: 100%;
    width: 100%;

    * {
      height: inherit !important;
      width: inherit !important;
    }
  }
}

// Card button

.card-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


// Card border

.card-border {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0; right: 0; left: 0;
    border-top-width: $border-width * 2;
    border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 2});
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: inherit;
    border-bottom-color: transparent;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
  }
}

.card-border-lg {

  &::after {
    border-top-width: $border-width * 3;
    border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 3});
  }
}

.card-border-xl {

  &::after {
    border-top-width: $border-width * 4;
    border-bottom-width: calc(#{$card-border-radius} - #{$border-width * 3});
  }
}


// Card group

.card-group {

  @include media-breakpoint-only(sm) {
    flex-direction: column !important;

    > .card {
      flex: auto;
    }
  }
}


// Card meta

.card-meta {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $card-spacer-x $card-meta-spacer-y;
}

.card-meta-divider {
  width: 100%;
  margin: 0 0 $card-meta-spacer-y;
}


// Card post

.card-row {
  
  .card-body {

    @include media-breakpoint-up(md) {
      padding: $card-row-spacer-y $card-row-spacer-x;
    }
  }

  .card-meta {

    @include media-breakpoint-up(md) {
      padding-left: $card-row-spacer-x;
      padding-right: $card-row-spacer-x;
    }
  }
}