.karriere-menu {

  table {

    thead {

      tr th:last-child,
      tr th + th {
        width: 20%;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
    tbody{

      tr td:last-child,
      tr td + td {
        width: 20%;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}