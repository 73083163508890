img.fade-out-spielerkachel {
    position: relative;
}

img.fade-in-spielerkachel {
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: opacity .2s ease-in-out;
	  background-color: #fff;
    }
}

p.fade-in-spielerkachel, div.fade-in-spielerkachel {
    font-family: OpenSans,sans-serif;

    @include media-breakpoint-up(md) {
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }
}

.spielerkachelcontainer {
    @include media-breakpoint-up(md) {
    }
}

.spielerkachel {
    position: relative;
    
    @include media-breakpoint-up(md) {
        max-width: 176px;
    }

    &:hover {
        img.fade-in-spielerkachel {
            opacity: 1;
        }

        p.fade-in-spielerkachel, div.fade-in-spielerkachel {
            @include media-breakpoint-up(md) {
                opacity: 1;
            }
        }
    }
}