.testimonial {
    background-image: url(/fileadmin/images/ecke.png);
  height: 550px;

    @include media-breakpoint-up(md) {
        background-image: url(/fileadmin/images/tor.png);
	  height: 382px;
	  margin-top: -30px;
    }
  
    .text-uppercase {
      font-size: .875rem;
    }

    .flickity-button {
        background-color: #ff5400;

        &:hover {
            background-color: #ff5400;
        }

        &:before, &:after {
            color: #fff;
        }
    }

  .flickity-prev-next-button.previous {
	left: -15px;

	@include media-breakpoint-up(md) {
	  left: 0;
	}
  }

  .flickity-prev-next-button.next {
	right: -15px;

	@include media-breakpoint-up(md) {
	  right: 0;
	}
  }

  blockquote {
	font-family: OpenSans;
	font-size: 1.0625rem;

	.blockquote-footer {
	  font-family: Montserrat;
	  opacity: .5;
	}
  }
}


section .logo-slider.py-8{
  padding-top: 3rem !important;
}

.image-slide {
  .flickity-viewport {
	border-radius: .625rem;
	box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1),16px 40px 75px -40px rgba(0,0,0,.2);
  }

  .flickity-button {
	background-color: #ff5400;

	&:hover {
	  background-color: #ff5400;
	}

	&:before, &:after {
	  color: #fff;
	}
  }

  .flickity-prev-next-button.previous {
	left: -15px;

	@include media-breakpoint-up(md) {
	  left: 0;
	}
  }

  .flickity-prev-next-button.next {
	right: -15px;

	@include media-breakpoint-up(md) {
	  right: 0;
	}
  }
}