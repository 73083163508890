body{
  font-family: OpenSans, sans-serif;
  font-size: 1.063rem;

  figcaption, p, li {
	color: #6a8188;
  }
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  font-family: Montserrat,serif;
  margin-bottom: .5rem;
  font-weight:600;
  line-height:1.2;
  letter-spacing: 0;
}

h1, .h1 {
  font-size: 2.5rem;
  margin-bottom: 1.3rem;

  @include media-breakpoint-up(sm) {
	font-size: 3rem;
	line-height: 3.875rem;
  }
}

.h2, h2 {
  font-size: 1.938rem;
  margin-bottom: 1rem;
  line-height: 2.5rem;
}

.h3,h3 {
  font-size: 1.3125rem;
  line-height: 1.75rem;
}

.h4, h4 {
  font-size: 1.1875rem;
  line-height: 1.75rem;
}

.h5, h5 {
  font-size: 1.0625rem;
  line-height: 1.55rem;
}

.h6, h6 {
  font-size: .75rem;
  line-height: 1.25rem;
}

.lead {
  color: #6A8188;
  font-size: 1.3125rem;
  line-height: 1.381;
}

.btn {
  font-family: Montserrat, sans-serif;
}

.text-primary-desat {
  color: #072d38 !important;
  font-family: "Montserrat", sans-serif;
}

.text-uppercase {
  font-size: 0.75rem;
}

.align-center{
  text-align:center;
}

blockquote {
  font-family: "Montserrat", sans-serif;

  p.gray-900 {
	color: $gray-900;
  }
}

a{
  &:hover{
    text-decoration: none;
  }
  &:focus{
    outline:none;
  }
}

.ce-gallery figcaption {
  line-height: 1.8;
}

section ul:not(.pagination):not(.list-social) {
  padding-left: 38px;

  li {
	position: relative;
	list-style: none;

	&:before {
	  position: absolute;
	  content: "\e92b";
	  width: calc(1em + .5rem);
	  text-align: center;
	  border-radius: 100%;
	  left: -38px;
	  top: 3px;
	  background-color: rgba(255,84,0,.1);
	  color: $primary;
	  font-family: 'Feather' !important;
	  font-size: 12.75px;
	  padding: .18px 4px;
	}
  }
}

.color-orange {
  color: #ff5400;
}

.color-black {
  color: #000000;
}


.text-regular {
  font-weight: 400;
}

.do-mobile-align-center{
  @include media-breakpoint-down(sm){
    text-align: center;
    display: block;
  }
}

.font-color-dark {
  color: #072d38;
}
.do-kununu{
  span{
    font-size: 15px;
  }
  a {
    text-align: center;
    display: block;
    margin-top: 15px;
  }
}