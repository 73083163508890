// 
// user.scss
// Use this to write your custom SCSS
//

.flickity-prev-next-button {
  top: 50%;
  width: calc(1.6em + 1.625rem + 2px);
  height: calc(1.6em + 1.625rem + 2px);
  border-radius: 50%;
  transform: translateY(-50%);
}

.flickity-button {
  position: absolute;
  background: hsla(0,0%,100%,.75);
  border: none;
  color: #333;
}

.flickity-enabled {
  position: relative;
  &:focus{
    outline: none;
  }

  &.is-draggable .flickity-viewport {
	cursor: grab;

	&.is-pointer-down {
	  cursor: grabbing;
	}

	.card-body h3 {
	  color: $black;
	}

	.card-border {
	  border-top-color: #FF5400;
	}
  }
}
.flickity-button:active {
  opacity: .6;
}
.mapboxgl-map canvas {
  height: 100% !important;
}

.zindex-1 {
  z-index: 1;
}

.align-1 {
  align-items: center !important;
}

.card-border {
  &:after {
	border-bottom-width: calc(.375rem - 11px); 
  }
}

.badge-light-soft {
  color: #072D38;
  background-color: #fff;
}

.bg-none .badge {
  border: 1px solid $gray-100;
}

.frame.screenshot-shadow {
  * {
	overflow: visible;
  }

  .ce-gallery, .ce-textpic {
	&:after {
	  display: block;
	  content: "";
	  clear: both;
	}
  }
}



.navbar-light .navbar-nav .nav-link {
  > .badge {
	color: #fff;
	background-color: rgba(7, 45, 56, 0.314);
  }

  &:hover > .badge, &:focus > .badge, &.active > .badge {
	background-color: #ff5400;
  }
}

#do-menu .job-badge, #mm-do-menu .job-badge {
  width: 20px;
  height: 20px;
  padding: 3px;
  font-weight: 400;
  border-radius: 50%;
  line-height: 15px;
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.container .row .container {
  padding-left: 15px;
  padding-right: 15px;
}

.container .row .col-default .container {
  padding-left: 0;
  padding-right: 0;
}

section {
  .frame-space-before-extra-small {
	padding-top: 1em;
	margin-top: 0;
  }

  .frame-space-after-extra-small {
	padding-bottom: 1em;
	margin-bottom: 0;
  }

  .frame-space-before-small {
	padding-top: 2em;
	margin-top: 0;
  }

  .frame-space-after-small {
	padding-bottom: 2em;
	margin-bottom: 0;
  }

  .frame-space-before-medium {
	padding-top: 3em;
	margin-top: 0;
  }

  .frame-space-after-medium {
	padding-bottom: 3em;
	margin-bottom: 0;
  }

  .frame-space-before-large {
	padding-top: 4em;
	margin-top: 0;
  }

  .frame-space-after-large {
	padding-bottom: 4em;
	margin-bottom: 0;
  }

  .frame-space-before-extra-large {
	padding-top: 5em;
	margin-top: 0;
  }

  .frame-space-after-extra-large {
	padding-bottom: 5em;
	margin-bottom: 0;
  }
}

.screenshot {
  &.no-shadow {
	box-shadow: none;
  }
}

.ce-below .ce-gallery {
  overflow: visible;

  * {
	overflow: visible;
  }

  a[href*="#c"] {
	display: block;
	margin-top: -20px;
  }
}

.ce-gallery.ce-border {
  img {
	border: none;
	border-radius: unset !important;
  }
}