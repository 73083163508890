.form-switch {
  min-height: 1.375rem;
  width: 50px;

  .form-check-input {
    -webkit-print-color-adjust: exact;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #d9e2ef;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: transparent;
    color-adjust: exact;
    height: 1em;
    margin-top: 0;
    vertical-align: top;
    width: 1em;
  }

  .form-check-input {
    background-position: 0;
    border-radius: 2.5rem;
    margin-left: -15px;
    transition: background-position .15s ease-in-out;
    width: 2.5rem;
    height: 1.375rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#fff'/%3E%3C/svg%3E");

    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#ff5400'/%3E%3C/svg%3E");
      background-color: #fff;
      background-position: 100%;
    }
  }
}