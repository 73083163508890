.newsletter-modal {
  padding-right: 0 !important;
  .modal-body {
    padding: 1rem;
    @include media-breakpoint-up(md) {
      padding: 2.5rem;
    }
    .newsletter-modal-img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
    .newsletter {
      > div {
        > div {
          .container {
            padding-bottom: 0 !important;
            > div {
              > div {
                .mandatory{
                  display: none;
                }
                form {
                  .form-row {
                    .newsletter-text {
                      flex: 0 0 100%;
                      max-width: 100%;
                      padding: 0 !important;
                    }
                    .newsletter-form {
                      flex: 0 0 100%;
                      max-width: 100%;
                      padding: 0 !important;

                      .btn {
                        width: 100%;
                        margin-top: 22px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

section .tx_evoweb_sfregister {
  .mandatory{
    display: none;
  }
  form {
    .form-row {
      .newsletter-text {
        display: none;
      }
      .newsletter-form {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;

        .newsletter-btn {
          margin-top: 22px;
        }
      }
    }
  }
}


//new newsletter styles

.newsletter-response{
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
}
.email.newsletter-form{
  .do-newsletter-flex{
    display: flex;
  }
  .form-group{
    flex: 2;
    margin-right: 15px;

    @media (min-width: 1200px) {
      margin-left: 70px;
    }
    .control-label{
      display: none;
    }
  }
 input[type="email"]{
   border: none;
   background-color: rgba(216, 216, 216,0.2);
   color: rgba(255,255,255,.4);
   &::placeholder{
     color: rgba(255,255,255,.4);
   }
 }
  span.submit{
    flex: 1;
    display: block;
    opacity: 0.8;
  }
}