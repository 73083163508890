footer {
  div[data-aos="fade-up"]:not(.py-6){
	  opacity: 1 !important;
	  transform: none !important;
  }

  .list-social-item {
	.list-social-icon {
	  max-width: 1.5rem;
	}
  }

  .list-unstyled a {
	line-height: 1.4375rem;
	color: #6A8188;
  }

  h6 {
	font-family: OpenSans;

	@include media-breakpoint-up(lg) {

	  &.text-uppercase {
	  	letter-spacing: 0;
	  }
	}
  }

  .text-muted {
	color: #6A8188 !important;
  }
}


// NEWSLETTER ANMELDUNG
footer {
	padding-top: 4rem;
	.container {
		.row {
			> div.col-12 {
				.container {
					padding: 0;
					padding-top: 0 !important;
					> div {
						border-bottom: 1px solid rgba(255, 255 ,255 ,0.15);
						padding-bottom: 50px;

						div.newsletter-text {
							color: #fff;
							font-size: 21px;
							line-height: 28px;
							font-family: "Montserrat", arial;
							font-weight: 300;
						}

						.tx_evoweb_sfregister {
							color: #fff;
							font-family: "Montserrat", arial;
							font-weight: 300;
						}

						> div {
							.mandatory {
								display: none;
							}
						}
						div form {
							padding: 0 !important;
							div.form-row {
								max-width: 100%;
								margin: 0;
								div.newsletter-text {
									color: #fff;
									font-size: 21px;
									line-height: 28px;
									font-family: "Montserrat", arial;
									font-weight: 300;
								}
								div.newsletter-form{
									padding-left: 0;
									padding-right: 0;
									@include media-breakpoint-up(md) {
										padding-left: 15px;
									}
									.form-control {
										border: 0;
										background-color: rgba(255, 255 ,255 ,0.15);
										display: inline;
										margin-bottom: 14px;
										color: #fff;
										@include media-breakpoint-up(md) {

										}
										@include media-breakpoint-up(lg) {
											margin-right: 14px;
											margin-bottom: 0;
											width: 60%;
										}

										&:focus {
											color: #fff;
										}
									}

									.errorMessage {
										color: #fff;
										margin-top: 5px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

  .do-newsletter-flex {
	.form-group + .form-group {
	  display: none;
	}
	input[id$="-text-1"] {
	  display: none;
	}
  }
}
