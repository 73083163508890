// Menu
.mm-panel {
  background-color: #fff;

  .mm-navbar{
	background-color: #072d38;
	color: #fff;
  }

  .nav-link {
    > .badge {
      background-color: #6a8188;
    }

    &:hover > .badge, &:focus > .badge, &.active > .badge {
      background-color: #ff5400;
    }
  }
  .mm-listview{

	.mm-listitem{
	  padding: 10px 20px;
	  a{
		color: #6a8188;
        transition: color .15s ease-in-out;

        &:hover {
          color: #ff5400;

        }
	  }
	  a.active{
		color: #ff5400;
	  }
	}
  }
}

.mm-btn_prev:before {
  border-color: #fff;
}
#mm-do-menu {
  .dropdown-menu {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
    a {
      font-weight: 600;
      padding: 0;
    }
  }
  .navbar-nav .nav-item + .nav-item{
    border-top: none;
  }
}
#mm-1{
  .dropdown-item{
    margin: 0;
  }
  .mm-listitem{
    border-color: rgba(0,0,0,0.1);
  }
}