.do-pricingtable {

  *:not(.btn):not(a) {
    color: #072D38;
  }

  .card-body {
    overflow: hidden;

    p {
      font-size: 15px;
      color: #072D38;
    }

    ul:not(.pagination):not(.list-social) {
      padding-left: 33px;

      li {
        font-size: 15px;
        color: #072D38;

        &:before {
          position: absolute;
          content: "";
          width: 3px;
          height: 3px;
          left: -22px;
          top: 10px;
          text-align: center;
          border-radius: 100%;
          background-color: #072D38;
          padding: 0;
        }
      }
    }
  }

  .badge {
    width: max-content;
    margin: auto;
    position: absolute;
    background: #fff;
    border: none;
    left: 0;
    right: 0;
    top: -12px;
    padding: 6px 12px;
  }

  .do-pricingtable-header {
    h2 {
      margin-bottom: 7px;
    }
  }

  hr {
    left: -50%;
    position: relative;
    width: 200%;
    border-top: 1px solid #e3e3e3;
    margin-top: 1px;
  }

  .do-pricingtable-description {

  }

  .do-pricingtable-extension-title {
    padding: 25px 0;
  }

  .list-group-item {
    border: none;
    padding: 0;

    &:first-of-type:before {
      content: "";
      left: -50%;
      position: absolute;
      width: 200%;
      border-top: 1px solid #e3e3e3;
      z-index: 1;
    }

     &:after {
      content: "";
      left: -50%;
      position: absolute;
      width: 200%;
      border-top: 1px solid #e3e3e3;
      z-index: 1;
    }
  }

  .collapse-chevron {
    i {
      color: #FF5400;
      height: 39px;
      width: 39px;
      position: relative;
      display: flex;
      background-color: #eee;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      transition: color .2s ease-in-out, background-color .2s ease-in-out;
    }
  }

  .price {
    font-size: 27px;
    font-style: italic;
    font-family: Montserrat,serif;
    font-weight: 400;
    text-align: center;
    line-height: 40px;
    color: #072D38;
    margin-bottom: 16px;
  }

  .website-configuration-link {
    display: block;
    text-align: center;
    margin-top: 20px;
  }

  .list-group-item-link {

    &:hover {
      .collapse-chevron {
        i {
          @include media-breakpoint-up(lg) {
            color: #FFF;
            background-color: #FF5400;
          }
        }
      }
    }
  }
}