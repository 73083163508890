// 
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

$primary: #ff5400;
$secondary: #506690;

$black: #072D38;